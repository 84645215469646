import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const WWGuidesTips: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Tips and Tricks</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_tips.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Tips and Tricks</h1>
          <h2>
            Tips and tricks for Wuthering Waves. What to do and not do in the
            game to start strong!
          </h2>
          <p>
            Last updated: <strong>20/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          To learn about the game systems, characters and more, check our
          beginner guide first that can be found linked below. In this guide we
          will instead focus on pure tips and tricks that may be hard to
          understand if you don't know how the game works.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner Guide"
            link="/wuthering-waves/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_beginner.jpg"
                alt="Beginner guide"
              />
            }
          />
        </Row>
        <SectionHeader title="General" />
        <StaticImage src="../../../images/ww/generic/tips_3.webp" alt="Tips" />
        <ul>
          <li>
            <strong>Focus on the Main Story first</strong> and only start
            roaming around when you're forced to level up to unlock the next
            Main Story mission.
          </li>
          <li>
            <strong>Explore the world</strong>. Exploration in this game is
            pretty rewarding as each area has tons of Treasures, special
            enemies, puzzles and quests hidden - for completing them you will
            not only get experience, but also Astrites, Tuners and various
            Materials.
          </li>
          <li>
            Do your exploration quests as they can affect the environment and
            make your exploration a lot more easier.
          </li>
          <li>
            Do not forget to visit NPCs such as{' '}
            <strong>
              Pioneer Association, Souvenir Shop, and Relic Merchant
            </strong>{' '}
            after exploration sessions to get even more resources.
          </li>
        </ul>
        <StaticImage src="../../../images/ww/generic/tips_4.webp" alt="Tips" />
        <ul>
          <li>
            <strong>Synthesizer</strong> allows you to both craft
            healing/revival items and lets you combine lower grade mats for
            their higher grade counterparts.
          </li>
          <li>
            You can buy weekly mats from the <strong>Weapon Franchise</strong>{' '}
            and there is a panel that allows you to craft purple weapons right
            behind him.
          </li>
          <li>
            Visit the grocery store to get the necessary items for cooking (you
            can also collect some of them in the overworld) and make yourself
            some delicious food that will buff your characters for a certain
            amount of time.
          </li>
          <li>
            <strong>
              Farming the ores in the overworld as you see them will come quite
              useful as you’ll need those to craft 4 star weapons
            </strong>
            .
          </li>
          <li>
            Do your <strong>Battle Pass Daily and Weekly missions</strong> - the
            free path rewards available there are actually decent.
          </li>
          <li>
            There's a set of weekly rewards you can obtain in Depths of Illusive
            Realm, so make sure to do enough runs to get them all.
          </li>
          <li>
            Traveling to a main fast-travel point (there is 1 for each area)
            will resurrect and fully heal your team, so save your healing items
            for when you don't have access to that.
          </li>
          <li>
            Always do your dailies as they provide a huge amount of Union Exp
            for your account.
          </li>
        </ul>
        <SectionHeader title="Leveling and Waveplate usage" />
        <StaticImage src="../../../images/ww/generic/tips_2.webp" alt="Tips" />
        <ul>
          <li>
            <strong>Don't let your Waveplate (stamina) overcap</strong>. 240
            Waveplate is the maximum amount you can store and regenerate over
            24h,{' '}
          </li>
          <li>
            <strong>Decide on what your main team</strong> is going to be as
            early as possible to avoid wasting resources and to be able to
            better focus on targeted echo farming.
          </li>
          <li>
            You ideally want to have a main dps, a off-dps, and a support in a
            team. Main characters want to be active on the field a lot more than
            other characters so putting 3 of them in a team will be more of a
            hindrance.
          </li>
          <li>
            <strong>
              Do not level more than 3 main dps characters as 3 are enough to
              clear Tower of Adversity in late game.
            </strong>
          </li>
          <li>
            Try to{' '}
            <strong>avoid using your Waveplates in Simulation Training</strong>{' '}
            at the start as you’ll get enough materials to upgrade your
            Resonators and Weapons through story rewards and exploration.
          </li>
          <li>
            <strong>Use your Waveplates on Overworld bosses instead</strong>,
            they provide you with ascension materials you’ll need. Overworld
            bosses will respawn 3 minutes after being killed, so if you want to
            farm the same one over and over just wait a couple minutes for it to
            respawn.
          </li>
          <li>
            <strong>
              In early game level your main dps to the max level they can be
            </strong>
            , and the remaining characters in your main team can stay 10 levels
            below to save on resources.
          </li>
          <li>
            Do not invest in 3 star weapons too much as they are just
            placeholders until you get 4 and 5 star weapons.
          </li>
          <li>
            Do not forget to do your weekly boss, and check which one you have
            to do depending on what Resonator you want to upgrade.
          </li>
        </ul>
        <SectionHeader title="Echoes" />
        <StaticImage src="../../../images/ww/generic/tips_1.webp" alt="Tips" />
        <ul>
          <li>
            <strong>
              Focus on getting your Data Bank level to level 15 as fast as
              possible as the Echo system is mainly locked behind it
            </strong>
            . The best way to do this is to just kill every enemy you come
            across.
          </li>
          <li>
            You can Track the Echoes you need the Data Bank experience from in
            Echo Gallery.
          </li>
          <li>
            There are unmarked{' '}
            <strong>
              mini bosses throughout the world that will give you a guaranteed
              purple or yellow rarity Echo
            </strong>
            , which will provide a huge amount of data bank experience. These
            enemies glow red and they do respawn after some time, but it's
            different for each of them,
          </li>
          <li>
            You can <strong>farm echoes in friends worlds</strong> to make it a
            lot more efficient.
          </li>
          <li>
            No need to force yourself to activate the 5-set bonuses on your
            Echoes in early game, you can instead go for two 2-set bonuses as it
            will be a lot easier to get while still providing a good amount of
            damage increase.
          </li>
          <li>
            <strong>Don't upgrade every Echo you find</strong>. The materials
            needed to do that are pretty limited and once you use it up.
          </li>
          <li>
            Do not level green or blue Echoes as they will become obsolete
            really fast.
          </li>
          <li>
            Do not go beyond +10/+15 with your purple and yellow Echoes in early
            game as it becomes exponentially more expensive to max out after
            that point.
          </li>
          <li>
            You can freely use your low rarity Tuners since they cannot be used
            on yellow echoes.
          </li>
        </ul>
        <SectionHeader title="Combat" />
        <ul>
          <li>
            <strong>
              Use the training mode available in your Resonator screen to learn
              how to properly use them.
            </strong>
          </li>
          <li>
            Do not skip combat tutorials as they teach you core mechanics that
            will be crucial for harder enemies.
          </li>
          <li>
            <strong>
              Wait for your Concerto skill to fill up before switching as it
              will provide you with buffs and extra damage.
            </strong>
          </li>
          <li>
            Stronger enemies have a stagger meter, they will become immobilized
            if you deplete this meter.
          </li>
          <li>
            If you dodge at the last moment of an attack, you’ll activate
            Extreme Evasion - slowing time for a moment.
          </li>
          <li>
            Try to choose the best active Echo for you; you can activate a
            special attack determined by the active Echo you have chosen, try
            and choose what you think is the best for your playstyle.
          </li>
          <li>
            <strong>Spam Echo ability!</strong> Echo abilities do not consume
            any resource other than their cooldown so don't be shy to use them
            as much as possible.
          </li>
        </ul>
        <SectionHeader title="Videos" />
        <p>For more tips, check the videos below!</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="5UFBkey6p7s" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="iViLhquC104" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="x2Y36ja0vXA" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesTips;

export const Head: React.FC = () => (
  <Seo
    title="Tips and Tricks | Wuthering Waves | Prydwen Institute"
    description="Tips and tricks for Wuthering Waves. What to do and not do in the game to start strong!"
    game="ww"
  />
);
